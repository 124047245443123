import { HOBBIES_CATEGORIES } from "data/taxonomies";
import { InterestsType } from "data/types";
import { observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import { goals, enterpriseGoals, onboardingStore } from "stores/OnboardingStore";

export interface WidgetTagsProps {
  className?: string;
  tags?: InterestsType[];
}

const InterestsWidgetTags: FC<WidgetTagsProps> = observer(({ className = "", tags = HOBBIES_CATEGORIES }) => {

  const [activeGoals, setActiveGoals] = useState(goals);

  useEffect(() => {
    // Check if there's an enterprise object in localStorage
    const enterpriseUser = localStorage.getItem('enterpriseParams');

    // If enterprise object exists, load enterprise goals, otherwise load regular goals
    if (enterpriseUser) {
      setActiveGoals(enterpriseGoals);
    } else {
      setActiveGoals(goals);
    }
  }, []);

  const toggleGoal = (goalName: string) => {
    onboardingStore.setGoals(goalName);
  };

  return (
    <div
      className={`nc-WidgetTags rounded-3xl overflow-hidden ${className}`}
      data-nc-id="WidgetTags"
    >
      <div className="max-w-md mx-auto p-4 rounded-md shadow-md">
        <div className="grid grid-cols-2 gap-4">
          {activeGoals.map((goal) => (
            <button
              key={goal.name}
              className={`flex flex-col items-center justify-center w-30 h-35 p-4 border rounded-md transition-colors duration-200 ${onboardingStore.goals.includes(goal.name) ? 'dark:bg-neutral-800 border-blue-700 rounded' : 'border-gray-300'}`}
              onClick={() => toggleGoal(goal.name)}
            >
              <span className="text-2xl mb-2">{goal.icon}</span>
              <span className="text-center text-sm">{goal.name}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
});

export default InterestsWidgetTags;
