import { UserProfileDTO } from 'api/dto/get-user-data-by-id.dto';
import { getMemberDataById } from 'api/user';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ChatArea from './ChatArea';
import linkedinAuthStore from 'stores/LinkedinAuthStore';


const ChatApp = () => {
    const [recipientData, setRecipientUserData] = useState<UserProfileDTO>(); // State to hold user data
    const { userId } = useParams<{ userId: string }>(); // Extract userId from URL

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await getMemberDataById(userId);
                setRecipientUserData(response.data);
            } catch (error) {
                console.error("Failed to fetch user data:", error);
            }
        };

        fetchUserData();
    }, [userId]);

    if (!linkedinAuthStore?.currentUser || !recipientData) {
        return null;
    }

    return (
        <div
            className="container mt-2 p-0"
        >
            {/* <Sidebar /> */}
            <ChatArea user={linkedinAuthStore.currentUser} recipient={recipientData} />
        </div>
    );
}

export default observer(ChatApp)
