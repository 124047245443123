import { logout } from 'api/auth';
import { updateUserProfileOnBoardingDone, updateUserProfile, updateUserAvatar } from 'api/profile';
import { deleteAccount, getUser } from 'api/user';
import { UpdateProfileDto, User } from 'interface/user.interface';
import _ from 'lodash';
import { makeAutoObservable, runInAction } from 'mobx';

class LinkedinAuthStore {
  currentUser: User | null = null;
  loading: boolean = false;
  error: string | null = null;

  constructor() {
    makeAutoObservable(this);

    // Initialize user state
    this.initUserState();
  }

  async initUserState() {
    try {
      const { data: user } = await getUser();

      this.currentUser = user || null;
      this.loading = false; // Set loading to false after API call
    } catch (error) {
      runInAction(() => {
        this.currentUser = null;
        this.loading = false; // Set loading to false on error
      });
    }
  }

  logout = async () => {
    this.currentUser = null;
    await logout()
    window.location.href = '/login'  // Navigate to the login page after logout
  }

  deleteAccount = async () => {
    if (this.currentUser) {
      try {
        await deleteAccount();
        window.location.href = '/login';
      } catch (error) {
        console.error('Error deleting account:', error);
      }
    }
  }

  // Handle user state changes
  async updateUser(updatedUser: Partial<User>) {
    if (_.isEmpty(this.currentUser)) {
      return
    }

    await updateUserProfileOnBoardingDone(updatedUser);

    if (this.currentUser && this.currentUser.profile) {
      this.currentUser.profile = {
        ...this.currentUser.profile,
        ...updatedUser,
      };
    }
  }

  async updateProfile(updatedUser: UpdateProfileDto) {
    if (_.isEmpty(this.currentUser)) {
      return
    }

    await updateUserProfile(updatedUser);

    if (this.currentUser && this.currentUser.profile) {
      this.currentUser.profile = {
        ...this.currentUser.profile,
        ...updatedUser,
      };
    }
  }

  async updateAvatar(formData: FormData) {
    const { data: avatarUrl } = await updateUserAvatar(formData)
    if (this.currentUser) {
      this.currentUser.profile.avatar = avatarUrl
    }
  }

  setUser(user: any) {
    this.currentUser = user;
    window.location.href = '/'
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  setError(error: string | null) {
    this.error = error;
  }

}

const linkedinAuthStore = new LinkedinAuthStore();
export default linkedinAuthStore;
