import { searchForComapny } from "api/company";
import debounce from "lodash/debounce";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import Input from "shared/Input/Input";
import { onboardingStore } from "stores/OnboardingStore";

interface Company {
    name: string;
    domain: string;
    logo_url: string;
}

const CompanySearch = observer(() => {
    const [query, setQuery] = useState<string>(""); // Search query
    const [companies, setCompanies] = useState<Company[]>([]); // List of companies
    const [loading, setLoading] = useState<boolean>(false); // Loading state
    const [showList, setShowList] = useState(false);

    useEffect(() => {
        // Clear companies when the query is cleared
        if (!query) {
            setCompanies([]);
        }
    }, [query]); // Add query to the dependency array to trigger re-render on query change

    // Function to fetch company data from the API
    const fetchCompanies = useCallback(async (searchQuery: string) => {
        if (!searchQuery) {
            setCompanies([]); // Clear companies list if search query is empty
            return;
        }

        try {
            setLoading(true); // Start loading
            const response = await searchForComapny(searchQuery);
            if (response.length > 0) {
                setShowList(true);
            }
            setCompanies(response); // Update the companies list
        } catch (error) {
            console.error("Error fetching companies:", error);
            setCompanies([]); // Clear the companies list in case of error
            setShowList(false);
        } finally {
            setLoading(false); // Stop loading
        }
    }, []);

    // Debounce the API call to avoid too many requests on every keystroke
    const debouncedFetchCompanies = useCallback(
        debounce(fetchCompanies, 500), // 500ms delay
        [fetchCompanies]
    );

    // Handle input change
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCompanies([]); // Clear the companies list when the input is cleared
        const { value } = event.target;
        onboardingStore.setCompanyName({
            name: value,
            logo_url: "",
            domain: "",
        });
        debouncedFetchCompanies(value); // Trigger the debounced search
    };

    // Handle company selection
    const handleCompanySelect = (company: Company) => {
        setShowList(false);
        onboardingStore.setCompanyName(company); // Save the selected company in state
    };

    // Handle error for logo image
    const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        event.currentTarget.src = "https://via.placeholder.com/150"; // Fallback image URL
    };

    return (
        <div className="w-full">
            {/* Search input */}
            <div className="mb-6">
                <Input
                    type="text"
                    value={onboardingStore?.company?.name}
                    onChange={handleInputChange}
                    placeholder="Company name"
                />
            </div>

            {/* Loading spinner */}
            {loading && <p className="text-gray-500 text-center">Loading...</p>}

            {/* Render company list */}
            <div className="space-y-4">
                {showList && companies.length > 0 ? (
                    companies.map((company) => (
                        <div
                            key={company.domain}
                            className="flex items-center space-x-4 p-3 border-b border-gray-200"
                            onClick={() => handleCompanySelect(company)} // Handle company click
                        >
                            <img
                                src={company.logo_url}
                                alt={company.name}
                                className="h-12 w-12 object-cover rounded-full"
                                onError={handleImageError} // Fallback image when logo fails
                            />
                            <div>
                                <h3 className="font-semibold text-lg">{company.name}</h3>
                                <p className="text-gray-500">{company.domain}</p>
                            </div>
                        </div>
                    ))
                ) : (
                    !loading && showList && <p className="text-gray-500 text-center">No companies found.</p>
                )}
            </div>
        </div>
    );
})

export default CompanySearch;
