import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";
import { ClimbingBoxLoader } from 'react-spinners';
import linkedinImage from '../../images/signin/linkedin.png';
import { useLocation, useNavigate } from 'react-router-dom';
import linkedinAuthStore from 'stores/LinkedinAuthStore';
import { observer } from 'mobx-react';
import Lottie from 'react-lottie';
import connections from '../../images/lottie/connections.json';
import MeetWithBlacLogo from '../../images/MEET WITH Main Logo.png'

export interface LinkedInSignInPageProps {
  className?: string;
}

const LinkedInSignInPage: React.FC<LinkedInSignInPageProps> = observer(({ className = '' }) => {
  const navigate = useNavigate(); // Use useNavigate if using react-router-dom v6
  const location = useLocation();
  const randomString = Math.random().toString(36).substring(2, 15);
  const clientId = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
  const redirectUri = `${process.env.REACT_APP_WEB_URL}/auth/linkedin/callback`;
  const scope = 'openid profile email w_member_social'; // Correct scope names
  const state = randomString;



  useEffect(() => {
    // Wait for loading to complete and ensure not in redirecting state
    if (!linkedinAuthStore.loading && linkedinAuthStore.currentUser) {
      navigate('/'); // Redirect to the main page if the user is authenticated
    }
  }, [linkedinAuthStore.currentUser, linkedinAuthStore.loading, navigate]);

  useEffect(() => {
    const queryParams = getQueryParams(location.search);

    // If there are query parameters, save them to localStorage
    if (Object.keys(queryParams).length > 0) {
      localStorage.setItem('enterpriseParams', JSON.stringify(queryParams));
      console.log('Query Params Saved:', queryParams);
    }
  }, [location.search]); // Runs when query parameters in the URL change

  // Function to get query parameters
  const getQueryParams = (search: string) => {
    const params = new URLSearchParams(search);
    const queryObject: any = {};
    params.forEach((value, key) => {
      queryObject[key] = value;
    });
    return queryObject;
  };


  const handleLinkedInLogin = () => {
    window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${encodeURIComponent(scope)}&state=${state}`;
  };

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: connections,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className={`nc-PageLogin ${className} h-dvh`} data-nc-id="PageLogin">
      {linkedinAuthStore.loading ? <div className="flex items-center justify-center h-screen">
        <ClimbingBoxLoader color="#bb86fc" />
      </div> :
        <div>
          <Helmet>
            <title>Access Your Account with Linkedin</title>
          </Helmet>
          <div className="container mb-24 lg:mb-32">
            <div className="flex justify-center mb-6">
              <img src={MeetWithBlacLogo} alt="Logo" style={{ maxWidth: '250px' }} />
            </div>
            <h2 className="my-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
              Access Your Account
            </h2>
            {/* Lottie animation */}
            <div className="flex justify-center">
              <Lottie options={lottieOptions} height={100} width={300} />
            </div>
            <div className="max-w-md mx-auto space-y-6">
              <span className="my-10 flex items-center text-l md:text-xl text-neutral-900 dark:text-neutral-100 text-center">
                Expand Your Network on the Go. Share your travels, connect with peers, and unlock new professional opportunities with every journey.
              </span>
              <div className="flex justify-center">
                <img
                  onClick={handleLinkedInLogin}
                  src={linkedinImage}
                  alt="Sign in with Linked In"
                  style={{ maxWidth: '200px', cursor: 'pointer' }}
                />
              </div>
              {/* OR */}
            </div>
          </div>
          {/* Footer */}
          <footer className="text-center mt-10">
            <p className="text-sm text-gray-600 dark:text-gray-400">
              By signing up you accept our <a href="https://valuable-crate-b4c.notion.site/Terms-And-Conditions-1099e55f776d8093b007fdd60f4c3b85" className="underline">Terms of Service</a> and <a href="https://valuable-crate-b4c.notion.site/Privacy-Policy-1099e55f776d802e95c1e373377c4472" className="underline">Privacy Policy</a>.
            </p>
          </footer>
        </div>}
    </div>
  );
})

export default LinkedInSignInPage;
