import { sendMatchSearchRequest } from 'api/matches';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Assuming you are using react-router for navigation
import Avatar from 'shared/Avatar/Avatar';

interface Match {
    id: string;
    fullName: string;
    industry: string;
    role: string;
    country: string;
    companyName: string;
    percentage: number;
    avatarUrl: string;
}

const Chat = () => {
    const [message, setMessage] = useState<string>('');
    const [matches, setMatches] = useState<Match[]>([]);
    const [isSending, setIsSending] = useState<boolean>(false);
    const navigate = useNavigate(); // For navigating to user profile

    const sendMessage = async () => {
        if (!message.trim()) return;

        setIsSending(true);  // Disable the send button

        try {
            const response = await sendMatchSearchRequest(message);

            setMatches(response);
        } catch (error) {
            console.error('Error searching for matches:', error);
        } finally {
            setIsSending(false);  // Enable the send button again
        }
    };

    const handleMatchClick = (id: string) => {
        navigate(`/user/${id}`); // Navigate to the user's page
    };


    console.log(matches);

    return (
        <div className="container p-4">
            <div
                id="scrollableDiv"
                className="flex flex-col overflow-y-auto mt-4 space-y-4"
                style={{ height: 'calc(100vh - 200px)' }}
            >
                {matches.map((match, index) => (
                    <div
                        key={index}
                        className="p-4 border border-gray-300 rounded-lg shadow-md cursor-pointer hover:shadow-lg transition duration-300 bg-white flex items-center"
                        onClick={() => handleMatchClick(match.id)}
                    >
                        {/* Avatar Section */}
                        <div className="mr-4">
                            <Avatar imgUrl={match.avatarUrl} userName={match?.fullName} sizeClass="w-16 h-16 rounded-full" />
                        </div>

                        {/* Info Section */}
                        <div className="flex-1">
                            <h2 className="text-lg font-semibold">{match.fullName}</h2>
                            <p className="text-sm text-gray-600">{match.role}</p>
                            <p className="text-sm text-gray-500">{match.industry}</p>
                        </div>

                        {/* Company & Match Info */}
                        <div className="text-right">
                            <p className="text-sm text-gray-600">{match.companyName}</p>
                            <p className="text-sm text-gray-500">{match.country}</p>
                            <p className="text-sm font-semibold text-green-500">{match.percentage}% Match</p>
                        </div>
                    </div>
                ))}

            </div>

            <div
                className="fixed bottom-0 left-0 right-0 flex items-center p-4 bg-gray-100"
            >
                <input
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            sendMessage();
                            e.preventDefault(); // Prevent new line in input
                        }
                    }}
                    placeholder="Type to search for matches..."
                    className="flex-1 bg-white border border-gray-300 rounded-full px-4 py-2 mr-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    disabled={isSending}  // Disable input while sending
                />
                <button
                    className={`bg-green-500 text-white rounded-full w-10 h-10 flex items-center justify-center ${isSending ? 'opacity-50 cursor-not-allowed' : ''}`}
                    onClick={sendMessage}
                    disabled={isSending}  // Disable button while sending
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <path d="M22 2L11 13"></path>
                        <path d="M22 2L15 22L11 13L2 9L22 2"></path>
                    </svg>
                </button>
            </div>
        </div>
    );
};

export default Chat;
