import { registerWithGoogleProvider } from 'api/register';
import { getUser } from 'api/user';
import {
    GoogleAuthProvider,
    isSignInWithEmailLink,
    onAuthStateChanged,
    sendSignInLinkToEmail,
    signInWithEmailLink,
    signInWithPopup,
    signOut
} from 'firebase/auth';
import { makeAutoObservable, runInAction } from 'mobx';
import { setAuthToken } from 'utils/setAuthorizationHeader';
import { auth } from '../firebase';
import { User } from 'interface/user.interface';

class AuthStore {
    currentUser: User | null = null;
    loading = true;

    constructor() {
        makeAutoObservable(this);

        // Initialize user state
        // this.initUserState();
    }

    async initUserState() {
        onAuthStateChanged(auth, async (firebaseUser) => {
            console.log('Is logged in:', firebaseUser);

            if (firebaseUser) {
                try {
                    const accessToken = await firebaseUser.getIdToken();
                    setAuthToken(accessToken);
                    const { data: user } = await getUser();
                    runInAction(() => {
                        this.currentUser = user;
                        this.loading = false;
                    });
                } catch (error) {
                    runInAction(() => {
                        this.currentUser = null;
                        this.loading = false;
                    });
                    // Handle sign-out or redirect to login
                }
            } else {
                runInAction(() => {
                    this.currentUser = null;
                    this.loading = false;
                });
                // Handle redirect to login if needed
            }
        });
    }

    async googleSignIn() {
        const provider = new GoogleAuthProvider();
        const result = await signInWithPopup(auth, provider);
        const { data: user } = await registerWithGoogleProvider(result.user);

        if (user) {
            this.currentUser = user;
        }
        // Update user state or navigate post-login
    }

    async logOut() {
        await signOut(auth);
        // this.currentUser = null;
        // Handle post-logout navigation or state cleanup
    }

    async sendSignInLinkToEmailHandler(email: string) {
        window.localStorage.setItem('emailForSignIn', email);
        const actionCodeSettings = {
            url: `${window.location.origin}/sign-in-completed`,
            handleCodeInApp: true,
        };
        await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    }

    isSignInWithEmailLinkHandler(emailLink: string) {
        return isSignInWithEmailLink(auth, emailLink);
    }

    async signInWithEmailLinkHandler(email: string, emailLink: string) {
        const result = await signInWithEmailLink(auth, email, emailLink);
        await registerWithGoogleProvider(result.user);
        window.localStorage.removeItem('emailForSignIn');
        // Handle navigation or state update post-sign-in
        if (result) {
            return true
        }
        return false
    }

    setLoading(status: boolean) {
        this.loading = status;
    }
}

export const authStore = new AuthStore();
