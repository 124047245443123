import { CompanyDetails } from 'interface/company';
import { makeAutoObservable } from 'mobx';

export interface OnboardingData {
    fullName: string;
    role: string;
    industry: string;
    location: {
        details: google.maps.GeocoderResult | null;
        latLng: google.maps.LatLngLiteral | null;
        geoCode?: google.maps.GeocoderResult | null;
    };
    goals: string[];
    interests: string[];
    languages: string[]
    company: CompanyDetails | null
    enterprise: boolean;
}

export const goals = [
    { id: 1, name: "Find a cofounder", icon: "🔍" },
    { id: 2, name: "Invest", icon: "💵" },
    { id: 3, name: "Mentor others", icon: "🎓" },
    { id: 4, name: "Explore new projects", icon: "🔭" },
    { id: 5, name: "Raise funding", icon: "💰" },
    { id: 6, name: "Business development", icon: "🧠" },
    { id: 7, name: "Start a company", icon: "🏢" },
    { id: 8, name: "Meet interesting people", icon: "👬" },
    { id: 9, name: "Bleisure", icon: "🏝" },
    { id: 10, name: "Get a job", icon: "💼" },
];

export const enterpriseGoals = [
    { id: 1, name: "Business Expansion", icon: "🌍" },
    { id: 2, name: "Client Acquisition", icon: "📈" },
    { id: 3, name: "Networking with Industry Leaders", icon: "🤝" },
    { id: 4, name: "Strategic Partnerships", icon: "🔗" },
    { id: 5, name: "Market Research", icon: "📊" },
    { id: 6, name: "Employee Recruitment", icon: "🧑‍💻" },
    { id: 7, name: "Cross-Industry Collaboration", icon: "🌐" },
    { id: 8, name: "Learning & Development", icon: "📚" },
    { id: 9, name: "Innovation & Technology", icon: "🚀" },
    { id: 10, name: "Sustainability Initiatives", icon: "♻️" },
];


class OnboardingStore {
    onBoardingStep: number = 1
    fullName: string = "";
    role: string = '';
    industry: string = '';
    languages: string[] = ["English"];
    // languages: language[] = [{ "name": "English", "nativeName": "English", "defaultChecked": true }];
    location: {
        details: google.maps.GeocoderResult | null;
        latLng: google.maps.LatLngLiteral | null;
    } = {
            details: null,
            latLng: null
        };
    goals: string[] = []
    interests: string[] = []
    company: CompanyDetails | null = null
    enterprise: boolean = false

    constructor() {
        makeAutoObservable(this);
    }

    // Action to update location
    updateLocation(newLocation: google.maps.GeocoderResult) {
        this.location = { ...this.location, ...newLocation };
    }

    // Computed value
    get onBoardingStepCurrentStatus() {
        return this.onBoardingStep;
    }

    get dataLength() {
        return this.fullName;
    }

    get locationObject() {
        return this.location
    }

    get getOnboardingData(): OnboardingData {
        return {
            fullName: this.fullName,
            role: this.role,
            industry: this.industry,
            location: this.location,
            goals: this.goals,
            interests: this.interests,
            languages: this.languages,
            company: this.company,
            enterprise: this.enterprise
        };
    }

    setOnboardingNextStep() {
        ++this.onBoardingStep
    }

    setOnboardingPrevStep() {
        this.onBoardingStep -= 1
    }

    setFullName(fullName: string) {
        this.fullName = fullName
    }

    setIsEnterprise(enterprise: boolean) {
        this.enterprise = enterprise
    }

    setLocation(locationDetails: google.maps.GeocoderResult) {
        this.location.details = locationDetails;
        const latLng = {
            lat: locationDetails.geometry.location.lat(),
            lng: locationDetails.geometry.location.lng()
        };
        this.location.latLng = latLng;
    }

    setRole(role: string) {
        this.role = role
    }

    setInsutry(industry: string) {
        this.industry = industry
    }

    setGoals(goalId: string) {
        this.goals = this.goals.includes(goalId)
            ? this.goals.filter((id) => id !== goalId)
            : this.goals.length < 3
                ? [...this.goals, goalId]
                : this.goals
    }

    setInterests(interest: string) {
        this.interests = this.interests.includes(interest)
            ? this.interests.filter((id) => id !== interest)
            : [...this.interests, interest];
    }
    

    setCompanyName(company: CompanyDetails) {
        this.company = company
    }

    clearLanguages() {
        console.log('clearing languages');

        this.languages = ["English"];
    }

    setLanguages(shouldAdd: boolean, langName: string) {
        if (shouldAdd) {
            // Add the language if it's not already present
            if (!this.languages.includes(langName)) {
                this.languages.push(langName);
            }
        } else {
            // Remove the language if it's present
            const index = this.languages.indexOf(langName);
            if (index !== -1) {
                this.languages.splice(index, 1);
            }
        }
    }

}

export const onboardingStore = new OnboardingStore();
