import { UserProfileDTO } from 'api/dto/get-user-data-by-id.dto';
import { getMemberDataById } from 'api/user';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import linkedinAuthStore from 'stores/LinkedinAuthStore';
import ChatArea from './ChatArea';
import Heading from 'components/Heading/Heading';


const ChatWithAIAssistant = () => {
    return (
        <div className="container p-0">

            <Heading className='ml-4 mt-4' desc="Discover business travelers and connect effortlessly.">Use AI for Finding Matches</Heading>
            {/* <Sidebar /> */}
            <div className="mb-[58px]">
                <ChatArea />
            </div>
        </div>
    );
};



export default observer(ChatWithAIAssistant)
