import React, { FC, useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import LocationAutocomplete from "components/Common/LocationAutocomplete";
import FooterNav from "components/FooterNav";
import Label from "components/Label/Label";
import { observer } from "mobx-react";
import moment from "moment-timezone";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import Autocomplete from "components/AutoComplete/AutoComplete";
import { Metadata } from "containers/OnBoarding/OnBoardingStep2";
import { getMetadata } from "api/metadata";
import linkedinAuthStore from "stores/LinkedinAuthStore";
import { UpdateProfileDto } from "interface/user.interface";
import CompanySearch from "components/CompanySearch/CompanySearch";

export interface AccountPageProps {
  className?: string;
}

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  const { currentUser } = linkedinAuthStore;
  const [metadata, setMetdata] = useState<Metadata>({
    industries: [],
    roles: [],
  });
  const [formFields, setFormFields] = useState<UpdateProfileDto>({
    id: currentUser?.id || "",
    fullName: currentUser?.profile.fullName,
    companyName: currentUser?.profile.companyName || "",
    industry: currentUser?.profile.industry,
    role: currentUser?.profile.role,
    placeDescription: currentUser?.profile.placeDescription,
    interests: currentUser?.profile.interests,
    avatar: currentUser?.profile.avatar,
    birthDate: currentUser?.profile.birthDate,
    phone: currentUser?.profile.phone || "",
    bio: currentUser?.profile.bio || "",
  });

  // State to handle delete account popup visibility
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  useEffect(() => {
    getMetadata().then((res) => {
      const { data } = res;
      setMetdata(data);
    });
  }, []);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormFields((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    try {
      linkedinAuthStore
        .updateProfile(formFields)
        .then(() => toast.success("Profile updated successfully"));
    } catch (error) {
      toast.error("An error occurred. Please try again");
    }
  };

  const handleLocationResult = (locationDetails: google.maps.GeocoderResult) => {
    const { formatted_address } = locationDetails;
    const placeDescription = formatted_address;
    setFormFields((prev) => ({
      ...prev,
      placeDescription,
      locationDetails,
    }));
  };

  const changeAvatar = (file: File) => {
    if (file.size > 5 * 1024 * 1024) {
      toast.error("File size should be less than 1MB");
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    linkedinAuthStore
      .updateAvatar(formData)
      .then(() => toast.success("Avatar updated successfully"))
      .catch(() => toast.error("An error occurred. Please try again"));
  };

  const handleChanges = (key: string, newRole: string) => {
    setFormFields((prev) => ({
      ...prev,
      [key]: newRole,
    }));
  };

  // Function to delete account
  const handleDeleteAccount = () => {
    linkedinAuthStore
      .deleteAccount()
      .then(() => {
        toast.success("Account deleted successfully");
        // Handle any necessary redirects or state updates after account deletion
      })
      .catch(() => toast.error("An error occurred while deleting the account"));
    setShowDeletePopup(false);
  };

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Account</title>
      </Helmet>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          <h2 className="text-3xl font-semibold">Account information</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex flex-col md:flex-row">
            <div className="flex-shrink-0 flex items-start">
              <div className="relative rounded-full overflow-hidden flex">
                <Avatar sizeClass="w-32 h-32" imgUrl={formFields?.avatar} />
                <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
                  <span className="mt-1 text-xs">Change Image</span>
                </div>
                <input
                  type="file"
                  className="absolute inset-0 opacity-0 cursor-pointer"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.files && e.target.files.length > 0) {
                      changeAvatar(e.target.files[0]);
                    }
                  }}
                />
              </div>
            </div>
            <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
              <div>
                <Label>Username</Label>
                <Input
                  className="mt-1.5"
                  defaultValue={formFields?.fullName || ""}
                  name="fullName"
                  onChange={handleInputChange}
                />
              </div>
              <div className="max-w-lg">
                <Label>Date of birth</Label>
                <Input
                  className="mt-1.5"
                  type="date"
                  defaultValue={moment(formFields?.birthDate).format(
                    "YYYY-MM-DD"
                  )}
                  name="birthDate"
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <Label>Address</Label>
                <LocationAutocomplete
                  address={formFields?.placeDescription || ""}
                  onLocationSelect={handleLocationResult}
                />
              </div>
              <div>
                <Label>Role</Label>
                <Autocomplete
                  defaultValue={formFields?.role}
                  options={metadata.roles.map((r) => r.value)}
                  placeholder="Select your role"
                  onChange={(val) => handleChanges("role", val)}
                />
              </div>
              <div>
                <Label>Industry</Label>
                <Autocomplete
                  defaultValue={formFields?.industry}
                  options={metadata.industries.map((r) => r.value)}
                  placeholder="Select your industry"
                  onChange={(val) => handleChanges("industry", val)}
                />
              </div>
              <div>
                <Label>About you</Label>
                <Textarea
                  className="mt-1.5"
                  defaultValue={formFields?.bio}
                  name="bio"
                  onChange={handleInputChange}
                />
              </div>
              <div className="pt-2 flex space-x-4">
                <ButtonPrimary onClick={handleSubmit}>Update Info</ButtonPrimary>
                <ButtonPrimary
                  className="bg-red-600 text-white px-4 py-2"
                  onClick={() => setShowDeletePopup(true)}
                >
                  Delete Account
                </ButtonPrimary>
              </div>
            </div>
          </div>
        </div>
      </CommonLayout>
      <FooterNav />

      {/* Modal using Headless UI */}
      <Dialog
        open={showDeletePopup}
        onClose={() => setShowDeletePopup(false)}
        className="fixed z-50 inset-0 flex items-center justify-center"
      >
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50"></div>
        <div className="bg-white p-6 rounded-lg max-w-md w-full z-10">
          <Dialog.Title className="text-xl font-bold">
            Confirm Account Deletion
          </Dialog.Title>
          <Dialog.Description className="mt-2">
            Are you sure you want to delete your account? This action cannot be undone.
          </Dialog.Description>
          <div className="mt-4 flex justify-end space-x-4">
            <button
              className="px-4 py-2 bg-gray-300 rounded-md"
              onClick={() => setShowDeletePopup(false)}
            >
              Cancel
            </button>
            <button
              className="px-4 py-2 bg-red-600 text-white rounded-md"
              onClick={handleDeleteAccount}
            >
              Delete
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default observer(AccountPage);
