import { Dialog, Tab, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { observer } from "mobx-react";
import { Fragment, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTimeoutFn } from "react-use";
import { searchFormStore } from "stores/Views/SearchFormStore";
import FlightSearchForm from "./(flight-search-form)/FlightSearchForm";
import ButtonSubmit from "./ButtonSubmit";
import linkedinAuthStore from "stores/LinkedinAuthStore";

// Default tabs
const defaultTabs = {
  buttonConfiguration: {
    text: 'Add Your Next Trip',
    subText: 'Collaborate • Connect • Travel',
    icon: (img: string) => <img className="w-10 h-10 rounded-full" src={img} alt="Rounded avatar" />
  },
  tabs: [
    {
      text: 'Add a trip',
      component: FlightSearchForm
    }
  ]
}

// Tabs for "trip" URL
// const tripTabs = {
//   buttonConfiguration: {
//     text: 'Manage Trip',
//     subText: 'Add a place • Explore • Add guests',
//     icon: MapIcon,
//   },
//   tabs: [
//     {
//       text: 'Places to visit',
//       component: AddTripPlaceForm,
//     }
//   ]
// }


const HeroSearchForm2Mobile = () => {
  // FOR RESET ALL DATA WHEN CLICK CLEAR BUTTON
  const [trigger, setTrigger] = useState(false);
  const location = useLocation().pathname;
  const tripId = location.split("/")[2];
  const tabsConfiguration = defaultTabs//location.includes("trip") ? tripTabs : defaultTabs;

  const [showDialog, setShowDialog] = useState(false);
  let [, , resetIsShowingDialog] = useTimeoutFn(() => setShowDialog(true), 1);

  function closeModal() {
    searchFormStore.setShowMobileModal(false);
  }

  function openModal() {
    setTrigger(false)
    searchFormStore.setShowMobileModal(true);
  }

  // Function to handle the external button click
  const handleExternalButtonClick = () => {
    setTrigger((prev) => !prev)
  }

  const renderButtonOpenModal = () => {

    return (
      <button
        onClick={openModal}
        className="relative flex items-center w-full border border-neutral-200 dark:border-neutral-6000 px-4 py-2 pr-11 rounded-full shadow-lg"
      >
        {/*Display the icon on the left side */}
        {tabsConfiguration.buttonConfiguration.icon(linkedinAuthStore.currentUser?.profile?.avatar || '')}

        <div className="ml-3 flex-1 text-left overflow-hidden">
          <span className="block font-medium text-sm">{tabsConfiguration.buttonConfiguration.text}</span>
          <div className="block mt-0.5 text-xs font-light text-neutral-500 dark:text-neutral-400 ">
            <span className="line-clamp-1">{tabsConfiguration.buttonConfiguration.subText}</span>
          </div>
        </div>

        <span className="absolute right-2 top-1/2 transform -translate-y-1/2 w-9 h-9 flex items-center justify-center rounded-full border border-neutral-200 dark:border-neutral-6000 dark:text-neutral-300">
          <svg
            viewBox="0 0 16 16"
            aria-hidden="true"
            role="presentation"
            focusable="false"
            className="block w-4 h-4"
            fill="currentColor"
          >
            <path d="M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
          </svg>
        </span>
      </button>
    );
  };

  return (
    <div className="HeroSearchForm2Mobile">
      {renderButtonOpenModal()}
      <Transition appear show={searchFormStore.showMobileModal} as={Fragment}>
        <Dialog
          as="div"
          className="HeroSearchFormMobile__Dialog relative z-max"
          onClose={closeModal}
        >
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <Dialog.Panel className="relative h-full overflow-hidden flex-1 flex flex-col justify-between ">
                  {showDialog && (
                    <Tab.Group manual>
                      <div className="absolute left-4 top-4">
                        <button className="" onClick={closeModal}>
                          <XMarkIcon className="w-5 h-5 text-black dark:text-white" />
                        </button>
                      </div>

                      <Tab.List className="pt-12 flex w-full justify-center font-semibold text-sm sm:text-base text-neutral-500 dark:text-neutral-400 space-x-6 sm:space-x-8">
                        {/* {["Flights", "Stay", "Experiences", "Cars"].map( */}
                        {tabsConfiguration.tabs.map(
                          (item, index) => (
                            <Tab key={index} as={Fragment}>
                              {({ selected }) => (
                                <div className="relative focus:outline-none focus-visible:ring-0 outline-none select-none">
                                  <div
                                    className={`${selected
                                      ? "text-black dark:text-white"
                                      : ""
                                      }  `}
                                  >
                                    {item.text}
                                  </div>
                                  {selected && (
                                    <span className="absolute inset-x-0 top-full border-b-2 border-black dark:border-white"></span>
                                  )}
                                </div>
                              )}
                            </Tab>
                          )
                        )}
                      </Tab.List>
                      <div className="flex-1 pt-3 px-1.5 sm:px-4 flex overflow-hidden">
                        <Tab.Panels className="flex-1 overflow-y-auto hiddenScrollbar py-4">
                          {tabsConfiguration.tabs.map((tab, index) => (
                            <Tab.Panel key={index} className="transition-opacity animate-[myblur_0.4s_ease-in-out]">
                              <tab.component onExternalTrigger={trigger} />
                            </Tab.Panel>
                          ))}
                          {/* <Tab.Panel>
                            <div className="transition-opacity animate-[myblur_0.4s_ease-in-out]">
                              <FlightSearchForm onExternalTrigger={trigger} />
                            </div>
                          </Tab.Panel>
                          <Tab.Panel>
                            <div className="transition-opacity animate-[myblur_0.4s_ease-in-out]">
                              <StaySearchForm />
                            </div>
                          </Tab.Panel>
                          <Tab.Panel>
                            <div className="transition-opacity animate-[myblur_0.4s_ease-in-out]">
                              <StaySearchForm />
                            </div>
                          </Tab.Panel>
                          <Tab.Panel>
                            <div className="transition-opacity animate-[myblur_0.4s_ease-in-out]">
                              <CarsSearchForm />
                            </div>
                          </Tab.Panel> */}
                        </Tab.Panels>
                      </div>
                      <div className="px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex justify-between">
                        <button
                          type="button"
                          className="underline font-semibold flex-shrink-0"
                          onClick={() => {
                            setShowDialog(false);
                            resetIsShowingDialog();
                          }}
                        >
                          Clear all
                        </button>
                        <ButtonSubmit
                          text="Add"
                          onClick={handleExternalButtonClick}
                          SvgIcon={<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 48 48"><g fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth={4}><path d="M42 6L4 20.138l20 3.87L29.005 44z"></path><path strokeLinecap="round" d="m24.008 24.008l5.657-5.656"></path></g></svg>}
                        />
                      </div>
                    </Tab.Group>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default observer(HeroSearchForm2Mobile)
