// axiosInstance.js
import axios from "axios";

// Create a single Axios instance
const axiosInstance = axios.create();

// Function to set the Authorization token
export const setAuthToken = (token = '') => {
    if (token) {
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete axiosInstance.defaults.headers.common['Authorization'];
    }
};

// Add an interceptor to handle 401 errors globally
axiosInstance.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            // Handle 401 error here
            console.error('Unauthorized access - Redirecting to login.');

            // Perform any cleanup actions (e.g., clear tokens or user data)
            // localStorage.clear();            
            // Redirect to the login page
            if (window.location.pathname !== '/login' && error.response.data.path !== '/authentication') {
                window.location.href = '/login';
            }
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;

// import axios from "axios";

// export default (token: string = '') => {
//     // Handle signout
//     if (token) {
//         axios.defaults.headers.common.authorization = `Bearer ${token}`;
//     } else {
//         delete axios.defaults.headers.common.authorization;
//     }

//     // Add handler for catching all 401 errors
//     axios.interceptors.response.use(
//         response => response,
//         error => {
//             if (error.response && error.response.status === 401) {
//                 // Handle 401 error here
//                 // For example, you can redirect to a login page
//                 // or show an error message to the user
//                 window.location.href = '/login';
//             }
//             return Promise.reject(error);
//         }
//     );
// };