// src/components/common/LocationAutocomplete/index.tsx
import { FC, useEffect, useState } from 'react';
import PlacesAutocomplete, {
    geocodeByAddress
} from 'react-places-autocomplete';
//"AIzaSyD8NDxby1gIQOt2sIeazztoBERKIglm2z4"

export interface LocationAutocomplete {
    address: string,
    placeHolder?: string,
    onLocationSelect: (locationDetails: google.maps.GeocoderResult) => void
}

const LocationAutocomplete: FC<LocationAutocomplete> = (props) => {
    const [address, setAddress] = useState(props.address);

    const handleChange = (address: any) => {
        const addressInEnglishOnly = address.replace(/[^a-zA-Z0-9\s,.-]/g, '');

        setAddress(addressInEnglishOnly)
    }
    const handleSelect = (selectedAddress: string) => {
        geocodeByAddress(selectedAddress)
            .then(async ([result]) => {
                // const latLng = await getLatLng(result);
                setAddress(result.formatted_address)
                props.onLocationSelect(result);
            });
    };

    useEffect(() => {
        setAddress(props.address);
    }, [props.address]);

    const searchOptions = {
        types: ['(cities)'],           // Restrict search to cities
        language: 'en'                 // Set the language to English
    };

    return (
        <PlacesAutocomplete
            value={address}
            onChange={handleChange}
            onSelect={handleSelect}
            debounce={500}
            searchOptions={searchOptions}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                    <input
                        {...getInputProps({
                            placeholder: props.placeHolder || 'Search Places ...',
                            className: 'location-search-input h-11 px-4 py-3 text-sm font-normal rounded-2xl',
                        })}
                    />
                    <div className="autocomplete-dropdown-container block mt-3 text-xs text-neutral-500 dark:text-neutral-400">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion, index) => {
                            // inline style for demonstration purpose
                            const isActive = suggestion.active;
                            const suggestionStyle = {
                                // backgroundColor: isActive ? '#fafafa' : '#ffffff',
                                cursor: 'pointer',
                                padding: '10px', // Added padding
                                borderBottom: index !== suggestions.length - 1 ? '1px solid #ddd' : undefined // Added divider except for last item
                            };
                            return (
                                <div
                                    {...getSuggestionItemProps(suggestion, {
                                        className: isActive ? 'suggestion-item--active' : 'suggestion-item',
                                        style: suggestionStyle,
                                    })}
                                >
                                    <span>{suggestion.description}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </PlacesAutocomplete>
    );
};

export default LocationAutocomplete;
