import { sendLinkedInCodeToBackend } from 'api/auth';
import MoonLoaderComponent from 'components/Loaders/MoonLoader';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import linkedinAuthStore from 'stores/LinkedinAuthStore';

const LinkedInCallback: React.FC = observer(() => {
    const navigate = useNavigate();

    // Refactored function to send the code and state to the backend
    const sendLinkedInCodeToBackendCall = async (code: string, state: string) => {
        // Check if the code is already processed
        const processedCode = localStorage.getItem('linkedinAuthCode');

        // If the code exists, prevent the backend call
        if (processedCode === code) {
            console.log('Authorization code already processed.');
            return;
        }

        // Otherwise, process the code
        linkedinAuthStore.setLoading(true); // Set loading to true

        try {
            // Save the code to localStorage to prevent further processing
            localStorage.setItem('linkedinAuthCode', code);

            // Call the backend with the LinkedIn code and state
            const user = await sendLinkedInCodeToBackend(code, state);
            console.log('User data:', user);
            // Handle success
            linkedinAuthStore.setUser(user); // Set user data in MobX store
            linkedinAuthStore.setLoading(false); // Set loading to false

            // After the process is complete, remove the code from localStorage
            localStorage.removeItem('linkedinAuthCode');
            // Navigate to the dashboard
            navigate('/');
        } catch (error) {
            // Handle error
            console.error('Error:', error);
            linkedinAuthStore.setError('LinkedIn authentication failed.'); // Set error in MobX store
            linkedinAuthStore.setLoading(false); // Set loading to false

            // Navigate to an error page
            // navigate('/login');

            // Remove the code from localStorage in case of error to allow retry
            localStorage.removeItem('linkedinAuthCode');
        }
    };


    useEffect(() => {
        const handleLinkedInCallback = async () => {
            // Extract code and state from the URL
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');
            const state = urlParams.get('state');

            if (code && state) {
                await sendLinkedInCodeToBackendCall(code, state);
            } else {
                // Redirect to login if code or state is missing
                console.log('Code or state is missing.');
                navigate('/login');
            }
        };

        handleLinkedInCallback();
    }, []);

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div>
                {!linkedinAuthStore.error ? (
                    <>
                        <h2 className="text-xl font-semibold mb-4">
                            {linkedinAuthStore.loading ? 'Loading...' : 'Please wait'}
                        </h2>
                        {linkedinAuthStore.loading && (
                            <MoonLoaderComponent />
                        )}
                    </>
                ) : (
                    <>
                        <div className="text-red-500 mt-4">
                            Oops! Something went wrong with LinkedIn authentication. Please try again later.
                        </div>
                        <div className="pt-8">
                            <ButtonPrimary href="/">Return Home Page</ButtonPrimary>
                        </div>
                    </>
                )}
            </div>
        </div>
    );

});
export default LinkedInCallback;
