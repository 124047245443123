import FooterNav from 'components/FooterNav';
import { observer } from 'mobx-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import linkedinAuthStore from 'stores/LinkedinAuthStore';

const MainPage = () => {
    const navigate = useNavigate();
    const navigateTo = (path: string) => {
        navigate(path);
    };

    return (
        <div className="container mt-4 flex flex-col items-center justify-center">
            {/* Title */}
            <h1 className="text-3xl font-bold text-center mb-12">
                What would you like to do today?🚀
            </h1>

            {/* Buttons */}
            <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 w-full md:w-auto px-4">
                {/* Button 2: Plan a Trip */}
                <button onClick={() => navigateTo('/home')} className="flex-1 border border-gray-400 text-gray-700 font-bold py-8 rounded-lg hover:shadow-lg transition duration-300 w-full md:w-1/3">
                    <span className="text-lg">Explore MeetWith</span>
                    <p className="text-sm text-gray-500 mt-2">Discover connections, opportunities, and events for business travelers.</p>
                </button>

                {/* Button 1: Find Opportunities */}
                {linkedinAuthStore.currentUser?.profile?.enterprise ?
                    <button onClick={() => navigateTo('/assistant')} className="flex-1 border border-gray-400 text-gray-700 font-bold py-8 rounded-lg hover:shadow-lg transition duration-300 w-full md:w-1/3">
                        <span className="text-lg">Find Potential Matches</span>
                        <p className="text-sm text-gray-500 mt-2 p-2">Use AI to discover and connect with business travelers.</p>
                    </button>
                    :
                    <button onClick={() => navigateTo('/meet-with')} className="flex-1 border border-gray-400 text-gray-700 font-bold py-8 rounded-lg hover:shadow-lg transition duration-300 w-full md:w-1/3">
                        <span className="text-lg">Meet With</span>
                        <p className="text-sm text-gray-500 mt-2">Discover new business opportunities.</p>
                    </button>
                }
                {/* Button 3: Find Activities */}
                <button onClick={() => navigateTo('/professional/locals/1')} className="flex-1 border border-gray-400 text-gray-700 font-bold py-8 rounded-lg hover:shadow-lg transition duration-300 w-full md:w-1/3">
                    <span className="text-lg">Set Up A Meeting</span>
                    <p className="text-sm text-gray-500 mt-2">Join or create activities for your business trip.</p>
                </button>

            </div>
            <FooterNav />
        </div>
    );
};

export default observer(MainPage);
