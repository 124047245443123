import  { AxiosResponse } from 'axios';
import { PaginationResult } from 'interface/pagination';
import axiosInstance from 'utils/setAuthorizationHeader';

export const PROFESSIONAL_LOCALS_BASE_URL = `${process.env.REACT_APP_API_URL}/users/like-minded-locals`
export const PROFESSIONAL_ARRIVALS_BASE_URL = `${process.env.REACT_APP_API_URL}/trip/to-my-country-next-month`

export interface ProfessionalProfileDto {
    id: number;
    firstName: string;
    lastName: string;
    displayName: string;
    avatar: string;
    bgImage?: string;
    desc: string;
    role: string;
    industry: string;
    bio: string;
    companyName: string;
    matchScore?: number;
}

export async function fetchLikeMindedLocals(url: string): Promise<AxiosResponse<PaginationResult<ProfessionalProfileDto[]>>> {
    return axiosInstance.get(url, { withCredentials: true })
}

export async function fetchLikeMindedArrivals(url: string): Promise<AxiosResponse<PaginationResult<ProfessionalProfileDto[]>>> {
    return axiosInstance.get(url, { withCredentials: true })
}