import AccountBilling from "containers/AccountPage/AccountBilling";
import AccountPage from "containers/AccountPage/AccountPage";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AuthorPage from "containers/AuthorPage/AuthorPage";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import ChatApp from "containers/ChatPage/ChatApp";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import EventOverviewPage from "containers/EventOverviewPage/EventOverviewPage";
import ItinerariesPage from "containers/ItinerariesPage/ItinerariesPage";
import ListingCarMapPage from "containers/ListingCarPage/ListingCarMapPage";
import ListingCarPage from "containers/ListingCarPage/ListingCarPage";
import ListingCarDetailPage from "containers/ListingDetailPage/listing-car-detail/ListingCarDetailPage";
import ListingExperiencesDetailPage from "containers/ListingDetailPage/listing-experiences-detail/ListingExperiencesDetailPage";
import ListingStayDetailPage from "containers/ListingDetailPage/listing-stay-detail/ListingStayDetailPage";
import ListingExperiencesMapPage from "containers/ListingExperiencesPage/ListingExperiencesMapPage";
import ListingExperiencesPage from "containers/ListingExperiencesPage/ListingExperiencesPage";
import ListingFlightsPage from "containers/ListingFlightsPage/ListingFlightsPage";
import ListingItineraryDetailPage from "containers/ListingItineraryPage/listing-itinerary-detail/ListingItineraryDetailPage";
import ListingRealEstateMapPage from "containers/ListingRealEstatePage/ListingRealEstateMapPage";
import ListingRealEstatePage from "containers/ListingRealEstatePage/ListingRealEstatePage";
import ListingStayMapPage from "containers/ListingStayPage/ListingStayMapPage";
import ListingStayPage from "containers/ListingStayPage/ListingStayPage";
import MagicLinkPage from "containers/MagicLinkPage/MagicLinkPage";
import MeetWithPage from "containers/MeetWithPage/MeetWithPage";
import MoreEventsPage from "containers/MoreEvents/MoreEventsPage";
import Notifications from "containers/NotificationsPage/Notifications";
import OnBoardingStep1 from "containers/OnBoarding/OnBoardingStep1";
import OnBoardingStep2 from "containers/OnBoarding/OnBoardingStep2";
import OnBoardingStep3 from "containers/OnBoarding/OnBoardingStep3";
import Page404 from "containers/Page404/Page404";
import PageAbout from "containers/PageAbout/PageAbout";
import PageAddListing1 from "containers/PageAddListing1/PageAddListing1";
import PageAddListing10 from "containers/PageAddListing1/PageAddListing10";
import PageAddListing2 from "containers/PageAddListing1/PageAddListing2";
import PageAddListing3 from "containers/PageAddListing1/PageAddListing3";
import PageAddListing4 from "containers/PageAddListing1/PageAddListing4";
import PageAddListing5 from "containers/PageAddListing1/PageAddListing5";
import PageAddListing6 from "containers/PageAddListing1/PageAddListing6";
import PageAddListing7 from "containers/PageAddListing1/PageAddListing7";
import PageAddListing8 from "containers/PageAddListing1/PageAddListing8";
import PageAddListing9 from "containers/PageAddListing1/PageAddListing9";
import PageContact from "containers/PageContact/PageContact";
import PageHome from "containers/PageHome/PageHome";
import PageHome2 from "containers/PageHome/PageHome2";
import PageHome3 from "containers/PageHome/PageHome3";
import LinkedInCallback from "containers/PageLinkedinLogin/LinkedInCallback";
import LinkedInSignInPage from "containers/PageLinkedinLogin/PageLinkedinLogin";
import PageMessages from "containers/PageMessages/PageMessages";
import PageMyGoals from "containers/PageMyGoals/PageMyGoals";
import PageMyInterest from "containers/PageMyInterest/PageMyInterest";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import PageVcFirm from "containers/PageVcFirm/PageVcFirm";
import PageVcFirms from "containers/PageVcFirms/PageVcFirms";
import PayPage from "containers/PayPage/PayPage";
import ProfessionalArrivalsPage from "containers/ProfessionalArrivalsPage/ProfessionalArrivalsPage";
import ProfessionalLocalsPage from "containers/ProfessionalLocalsPage/ProfessionalLocalsPage";
import SiteHeader from "containers/SiteHeader";
import TripOverviewPage from "containers/TripOverviewPage/TripOverviewPage";
import UserPage from "containers/UserPage/UserPage";
import useWindowSize from "hooks/useWindowResize";
import { observer } from "mobx-react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from "shared/Loader/Loader";
import linkedinAuthStore from "stores/LinkedinAuthStore";
import ProtectedRoute from "./ProtectedRoute";
import ScrollToTop from "./ScrollToTop";
import { Page } from "./types";
import MainPage from "containers/MainPage/MainPage";
import ChatWithAIAssistant from "containers/AIChatAssistantPage/ChatWithAIAssistant";

export const pages: Page[] = [
  { path: "/", exact: true, component: MainPage, protected: true },
  { path: "/home", exact: true, component: PageHome, protected: true },
  { path: "/#", exact: true, component: PageHome, protected: true },
  { path: "/home-1-header-2", exact: true, component: PageHome, protected: true },
  { path: "/home-2", component: PageHome2, protected: true },
  { path: "/home-3", component: PageHome3, protected: true },
  //
  { path: "/listing-stay", component: ListingStayPage, protected: true },
  { path: "/listing-stay-map", component: ListingStayMapPage, protected: true },
  { path: "/listing-stay-detail", component: ListingStayDetailPage, protected: true },
  //
  {
    path: "/listing-experiences",
    component: ListingExperiencesPage,
    protected: true
  },
  {
    path: "/listing-experiences-map",
    component: ListingExperiencesMapPage,
    protected: true
  },
  {
    path: "/listing-experiences-detail",
    component: ListingExperiencesDetailPage,
    protected: true
  },
  //
  { path: "/listing-car", component: ListingCarPage, protected: true },
  { path: "/listing-car-map", component: ListingCarMapPage, protected: true },
  { path: "/listing-car-detail", component: ListingCarDetailPage, protected: true },
  //
  { path: "/listing-real-estate-map", component: ListingRealEstateMapPage, protected: true },
  { path: "/listing-real-estate", component: ListingRealEstatePage, protected: true },
  //
  { path: "/listing-flights", component: ListingFlightsPage, protected: true },
  //
  { path: "/checkout", component: CheckOutPage, protected: true },
  { path: "/pay-done", component: PayPage, protected: true },
  //
  { path: "/author", component: AuthorPage, protected: true },
  { path: "/account-password", component: AccountPass, protected: true },
  { path: "/account-savelists", component: AccountSavelists, protected: true },
  { path: "/account-billing", component: AccountBilling, protected: true },
  //
  { path: "/blog", component: BlogPage, protected: true },
  { path: "/blog-single", component: BlogSingle, protected: true },
  //
  { path: "/add-listing-1", component: PageAddListing1, protected: true },
  { path: "/add-listing-2", component: PageAddListing2, protected: true },
  { path: "/add-listing-3", component: PageAddListing3, protected: true },
  { path: "/add-listing-4", component: PageAddListing4, protected: true },
  { path: "/add-listing-5", component: PageAddListing5, protected: true },
  { path: "/add-listing-6", component: PageAddListing6, protected: true },
  { path: "/add-listing-7", component: PageAddListing7, protected: true },
  { path: "/add-listing-8", component: PageAddListing8, protected: true },
  { path: "/add-listing-9", component: PageAddListing9, protected: true },
  { path: "/add-listing-10", component: PageAddListing10, protected: true },
  //
  { path: "/contact", component: PageContact, protected: false },
  { path: "/about", component: PageAbout, protected: false },
  { path: "/signup", component: PageSignUp, protected: false },
  { path: "/login", component: LinkedInSignInPage, protected: false },
  { path: "/auth/linkedin/callback", component: LinkedInCallback, protected: false },
  { path: "/subscription", component: PageSubcription, protected: false },
  { path: "/sign-in-completed", component: MagicLinkPage, protected: false },
  //Onboarding
  { path: "/onboarding-step-1", component: OnBoardingStep1, protected: true },
  { path: "/onboarding-step-2", component: OnBoardingStep2, protected: true },
  { path: "/onboarding-step-3", component: OnBoardingStep3, protected: true },
  { path: "/event/:id", component: EventOverviewPage, protected: true },
  { path: "/trip/:id", component: ListingItineraryDetailPage, protected: true },

  { path: "/account", component: AccountPage, protected: true },
  { path: "/my-goals", component: PageMyGoals, protected: true },
  { path: "/my-interests", component: PageMyInterest, protected: true },
  { path: "/user/:userId", component: UserPage, protected: true },
  { path: "/my-trips", component: ItinerariesPage, protected: true },
  { path: "/meet-with", component: MeetWithPage, protected: true },
  { path: "/chat/:userId", component: ChatApp, protected: true },
  { path: "/trip-overview/:tripId", component: TripOverviewPage, protected: true },
  { path: "/events/:sourceId", component: MoreEventsPage, protected: true },
  { path: "/chats", component: PageMessages, protected: true },
  { path: "/vc-firms", component: PageVcFirms, protected: true },
  { path: "/vc-firm/:id", component: PageVcFirm, protected: true },
  { path: "/professional/locals/:tripId", component: ProfessionalLocalsPage, protected: true },
  { path: "/professional/arrivals", component: ProfessionalArrivalsPage, protected: true },
  { path: "/notifications", component: Notifications, protected: true },
  { path: "/assistant", component: ChatWithAIAssistant, protected: true },
  //
];


const MyRoutes = () => {
  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }

  if (linkedinAuthStore?.loading) {
    return <Loader />;
  }

  return (
    <BrowserRouter>
      <ToastContainer theme="colored" />
      <ScrollToTop />
      <SiteHeader />
      <Routes>
        {pages.map(({ component, path, protected: isProtected }) => {
          const Component = component;

          // Applying ProtectedRoute to specific routes
          if (isProtected) {
            return <Route key={path} path={path} element={<ProtectedRoute path={path} component={Component} />} />;
          }

          // Regular Route for other pages
          return <Route key={path} path={path} element={<Component />} />;
        })}
        <Route path="*" element={<Page404 />} />
      </Routes>

      {/* {WIN_WIDTH < 768 && <FooterNav />} */}
      {/* <Footer /> */}
    </BrowserRouter>
  );
};

export default observer(MyRoutes);
