import { UserProfileDTO } from "api/dto/get-user-data-by-id.dto";
import { fetchLikeMindedLocals, PROFESSIONAL_LOCALS_BASE_URL, ProfessionalProfileDto } from "api/professionalLocals";
import { fetchVcFirmsByUrl, VC_FIRMS_BASE_URL, VentureCapitalFirmFilterDto } from "api/vc-firms";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import FooterNav from "components/FooterNav";
import Heading from "components/Heading/Heading";
import MoonLoaderComponent from "components/Loaders/MoonLoader";
import NoResultCard from "components/NoResult/GenericNoResultCard";
import SectionEventsSlider from "components/SectionEventsSlider/SectionEventsSlider";
import SectionSliderDiscoverLocals from "components/SectionSliderDiscoverLocals/SectionSliderDiscoverLocals";
import SectionSliderMatchingBusinessTravelers from "components/SectionSliderMatchingBusinessTravelers/SectionSliderMatchingBusinessTravelers";
import { TripDetails } from "interface/trip.interface";
import { observer } from "mobx-react";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import eventStore from "stores/EventsStore";
import { notificationStore } from "stores/NotificationsStore";
import { tripStore } from "stores/TripStore";
import HeaderFilter from "./HeaderFilter";
import WidgetVentureCapital from "./Widget/WidgetVentureCapital";
import { MATCHING_TRAVELERS_BASE_URL } from "api/trip";
import linkedinAuthStore from "stores/LinkedinAuthStore";

function PageHome() {
  const [activeTab, setActiveTab] = useState("Explore");
  const [currentTrip, setCurrentTrip] = useState<TripDetails | null>(null);
  const [vcFirms, setVcFirms] = useState<VentureCapitalFirmFilterDto[]>([]);
  const [professionalLoclas, setProfessionalLocals] = useState<ProfessionalProfileDto[]>([]);

  const startOfNextPeriod = moment().startOf('day').format('ll');
  const endOfNextPeriod = moment().add(31, 'days').endOf('day').format('ll')

  // Fetch trips for the next month when the component mounts
  useEffect(() => {
    fetchVcFirmsByUrl(VC_FIRMS_BASE_URL).then(({ data }) => {
      setVcFirms(data.items)
    })

    if (activeTab === "Explore") {
      const country = linkedinAuthStore.currentUser?.profile.country

      fetchLikeMindedLocals(PROFESSIONAL_LOCALS_BASE_URL).then(res => setProfessionalLocals(res.data.items))
      country && tripStore.fetchNextMonthArrivals(country);
    } else if (currentTrip) {

      tripStore.fetchMatchingTravelers(MATCHING_TRAVELERS_BASE_URL + currentTrip.id);
      tripStore.fetchLocalsAtDestination(currentTrip.id);
    }

  }, [activeTab, currentTrip]);

  useEffect(() => {
    tripStore.fetchMyTrips()
    notificationStore.chekForUnreadMessages()
  }, []);

  function setActiveTabHandler(currentTab: string, trip: TripDetails) {
    setActiveTab(currentTab)
    setCurrentTrip(trip)
  }

  const renderActiveSection = () => {
    switch (activeTab) {
      case "Explore":
        return (<>
          <Heading className="mb-5 lg:mb-5 text-neutral-500 dark:text-neutral-50" desc={""} viewAll={{ label: "View all", href: "/professional/locals/1" }}>
            Meet Nearby Locals
          </Heading>
          <SectionSliderDiscoverLocals
            likeMindedLocals={professionalLoclas}
            uniqueClassName="LocalMatches"
          />
          <Heading
            className="mb-5 lg:mb-5 text-neutral-500 dark:text-neutral-50"
            desc={`${startOfNextPeriod} - ${endOfNextPeriod}`}
            viewAll={{ label: "View all", href: "/professional/arrivals" }}
          >
            Arrivals Next Month
          </Heading>
          {tripStore.paginatedArrivalsNextMonth.length ? <SectionSliderMatchingBusinessTravelers
            userMatches={tripStore.paginatedArrivalsNextMonth}
            uniqueClassName="ArrivalsNextMonth"
          /> : <NoResultCard displayName="No matching for business travelers... yet." />}

          <WidgetVentureCapital vcs={vcFirms} />
        </>);
      default:
        return (
          <>
            <Heading
              className="mb-5 lg:mb-5 text-neutral-500 dark:text-neutral-50"
              desc={`${moment(currentTrip?.startDate).startOf('day').format('ll')} - ${moment(currentTrip?.endDate).endOf('day').format('ll')}`}
            >
              Flight to {currentTrip?.toCity}
            </Heading>
            <Heading
              className="mb-5 lg:mb-5 text-neutral-500 dark:text-neutral-50"
              desc=""//"Find and connect with business travelers heading to your destination at the same time."
              viewAll={{ label: "View all", href: "professional/to-destination" }}
            >
              Meet Fellow Business Travelers at Your Destination
            </Heading>
            {tripStore.matchingTravelers.length ? <SectionSliderMatchingBusinessTravelers
              userMatches={tripStore.matchingTravelers}
              uniqueClassName="MatchingTravelersToDestination"
            /> : <NoResultCard displayName="No matching travelers" />}
            <Heading
              className="mb-5 lg:mb-5 text-neutral-500 dark:text-neutral-50"
              desc=""
              viewAll={{ label: "View all", href: `professional/locals/${currentTrip?.id}` }}
            >
              Connect with Locals at Your Destination
            </Heading>
            {tripStore.localsAtDestination.length ? <SectionSliderMatchingBusinessTravelers
              userMatches={tripStore.localsAtDestination}
              uniqueClassName="MatcchingLocalsAtDestination"
            /> : <NoResultCard displayName="No locals at destination" />}
          </>
        );
    }
  };

  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <Helmet>
        <title>Home Page</title>
      </Helmet>
      <BgGlassmorphism />
      {/* <BackgroundSection /> */}

      <div className="container relative space-y-10 mb-24 lg:space-y-28 lg:mb-28">
        {/* SECTION */}
        <div className="relative">
          <HeaderFilter
            tabActive={activeTab}
            // heading={"Upcoming events nearby"}
            tabs={tripStore.myTrips}
            // subHeading={"Discover events nearby to enhance your professional and leisure pursuits."}
            onClickTab={(tab, trip) => setActiveTabHandler(tab, trip)}
          />
        </div>
        {eventStore.isLoading ? (
          <MoonLoaderComponent />
        ) : eventStore.events.length ? (
          <SectionEventsSlider
            sourceId={currentTrip?.id || 'localEvents'}
            events={eventStore.events}
            uniqueClassName="events"
          />
        ) : null}
        {renderActiveSection()}
        {/* SECTION Next month arrivals */}
        {/* <div className="relative py-16">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionGridTravelersBox
            title="Discover Business Opportunities Next Month"
            description="Ratings Powered by Advanced AI Insights"
            trips={tripStore.nextMonthArrivals} />
        </div> */}

        {/* SECTION HERO */}
        {/* <SectionHero className="pt-10 lg:pt-16 lg:pb-16" /> */}

        {/* SECTION like minded loacls */}
        {/* <SectionSliderDiscoverLocals
          heading="Connect Nearby: Discover Like-Minded Locals"
          subHeading="Explore and connect with locals who share your passions, right in your neighborhood."
          likeMindedLocals={likeMindedLocalsStore.likeMindedLocals?.items}
          uniqueClassName="PageHome_s1"
        /> */}
        {/* <SectionSliderNewCategories
          categories={DEMO_CATS}
          uniqueClassName="PageHome_s1"
        /> */}

        {/* SECTION2 */}
        {/* <SectionOurFeatures /> */}

        {/* SECTION */}
        {/* <SectionHowItWork /> */}

        {/* SECTION 1 */}
        {/* <div className="relative py-16">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionSliderNewCategories
            categories={DEMO_CATS_2}
            categoryCardType="card4"
            itemPerRow={4}
            heading="Suggestions for discovery"
            subHeading="Popular places to stay that Chisfis recommends for you"
            sliderStyle="style2"
            uniqueClassName="PageHome_s2"
          />
        </div> */}

        {/* SECTION */}
        {/* <SectionSubscribe2 /> */}



        {/* SECTION */}
        {/* <SectionGridCategoryBox /> */}

        {/* SECTION */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionBecomeAnAuthor />
        </div> */}

        {/* SECTION 1 */}
        {/* <SectionSliderNewCategories
          heading="Explore by types of stays"
          subHeading="Explore houses based on 10 types of stays"
          categoryCardType="card5"
          itemPerRow={5}
          uniqueClassName="PageHome_s3"
        /> */}

        {/* SECTION */}
        {/* <SectionVideos /> */}

        {/* SECTION */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageHome_" />
        </div> */}
      </div>
      <FooterNav />
    </div>
  );
}

export default observer(PageHome);
